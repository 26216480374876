import React, {useContext, useEffect} from 'react'
import ProductTitle from '../../components/publisher/ProductTitle'
import ProductDescription from './ProductDescription'
import ProductPrice from '../../components/publisher/ProductPrice'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from './BundlesProvider'
import SpinnerOverlay from '../react_components/SpinnerOverlay'

export default function MetadataPanel() {
	const productContext = useContext(ProductContext)
	const { product } = productContext
	const {
		metadataForm
	} = useContext(BundlesContext)

	const {
		setBundleTitle,
    setBundlePrice,
		isPublishing,
	} = metadataForm
  
    useEffect(() => {
			setBundleTitle(product.name)
      if (/^\d*$/.test(product.price)) {
        setBundlePrice(Number(product.price).toFixed())
      }
      if (product.description) {
        metadataForm.setBundleDescription(product.description)
      }
    }, []);

    return (
      <div className="opp-right-pane-wrapper">
          <div className="panel panel-default name-panel">
            <div className="panel-body panel-collapse collapse in">
              <div className="panel-body-container">
                <div className="form-groups-wrapper" style={{display: 'flex', flexDirection: 'column'}}>
                  <ProductTitle />
                  <ProductPrice />
                </div>
              </div>
            </div>
          </div>
          <div className="panel panel-default description-panel">
            <div className="panel-body panel-collapse collapse in">
              <div className="panel-body-container">
                <ProductDescription />
              </div>
            </div>
          </div>
          {isPublishing && (
            <SpinnerOverlay>
              { I18n.t('turbosquid.products.product_files_panel.processing') }
            </SpinnerOverlay>
            )}
      </div>
    )
}