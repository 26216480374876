import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from '../../products/bundles/BundlesProvider'
import Combobox from '../../products/react_components/form_components/combobox_field'

import FormError from './ProductFiles/FormError'

const i18nOptions = {
  scope: 'turbosquid.products.product_name_panel'
}

const bundlesi18Options = {
  scope: 'turbosquid.products.bundles_edit_panel'
}

export const errorMessage = (translation, options = {}) => {
  return (
    <>
      <a onClick={() => {}} className="field-link">
        {`${I18n.t('price', i18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, { ...i18nOptions, ...options })}</span>
    </>
  )
}

export default function ProductPrice() {
  const { register, control } = useFormContext()
  const { product } = useContext(ProductContext)
  const inputName = 'product-price'
  const { metadataForm, searchSelect } = useContext(BundlesContext)

  const {
    bundlePrice,
    setBundlePrice,
    bundlePriceValidation,
    setBundlePriceValidation
  } = metadataForm

  const { totalPrice } = searchSelect

  const discount = (totalPrice) => {
    return ((Math.round(totalPrice * 100) / 100) * .9).toFixed()
  }

  const calculateDiscount = (totalPrice, percentage) => {
    return (totalPrice * (1 - percentage / 100)).toFixed(2)
  }

  const generatePriceOptions = (totalPrice) => {
    if (totalPrice == 0) {
      return []
    }
  
    const discountPercentages = [10, 15, 20, 25, 30, 35, 40, 45, 50]
    return discountPercentages.map((percentage) => {
      const discountedPrice = Math.floor(calculateDiscount(totalPrice, percentage)).toFixed()
      return {
        value: discountedPrice,
        label: (
          <>
            <strong>${discountedPrice}</strong> ({percentage}% off)
          </>
        )
      }
    })
  }

  const priceOptions = totalPrice ? generatePriceOptions(totalPrice) : []

  return (
    <div className="form-group turbosquid_product_form_price" style={{ width: '100%', padding: '10px 0 0 0' }}>
      <label className="control-label" htmlFor={inputName}>
        {I18n.t('price', bundlesi18Options)}
      </label>
      <Combobox
        name={inputName}
        id={inputName}
        control={control}
        options={priceOptions}
        errorMessage={errorMessage}
        setBundlePriceValidation={setBundlePriceValidation}
        totalPrice={totalPrice}
        register={register}
        setBundlePrice={setBundlePrice}
        bundlePrice={bundlePrice}
        product={product}
      />
      {bundlePriceValidation && <FormError error={bundlePriceValidation} />}
      <div>
        {I18n.t('total_price', bundlesi18Options)} <span data-testid="total-price">{`$${totalPrice}`}</span>
      </div>
      <div>
        {I18n.t('recommended_price1', bundlesi18Options)} <span data-testid="recommended-price">{`$${discount(totalPrice)}`}</span> {I18n.t('recommended_price2', bundlesi18Options)}
      </div>
    </div>
  )
}
