export const ComboSelectStyle = {
  control: (provided) => ({
    ...provided,
    // background: '#e8e8e8',
    // border: '1px solid #ccc',
    background: '#f6f6f6',
    border: '1px solid #d7d7d7',
    borderRadius: 2,
    color: 'gray',
    height: 32,
    outline: 'none',
    minHeight: 0,
  }),
  menu: (provided) => ({
    ...provided,
    background: '#FFFFFF',
    margin: 0,
    borderRadius: '6px',
    padding: '10px, 1px, 10px, 1px',
    gap: '5px',
    border: '1px solid #CECDC3'
  }),
  valueContainer: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    background: 'none',
    color: state.isSelected || state.isFocused ? '#808080' : '#808080',

    '&:hover': {
      background: '#EFEFE8',
      color: 'gray'
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'gray',
    // top: 2,
    // transform: 'none',
  }),
  input: (provided) => ({
    ...provided,
    color: '#808080',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 32,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s ease',
  }),
}
