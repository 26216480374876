import React, { forwardRef, useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable'
import { Controller } from 'react-hook-form'
import { ComboSelectStyle } from '../../../utils/ComboSelectStyle'
import { HIGHER_PRICE_BOUND, LOWER_PRICE_BOUND, validate, stripHTMLFromString, clearTitleAndTagsForbiddenWords
} from '../helpers'

const Combobox = forwardRef(({
  name,
  control,
  options,
  type,
  totalPrice,
  setBundlePriceValidation,
  errorMessage,
  setBundlePrice,
  register,
  product,
  bundlePrice,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleValidation = value => {
    if (totalPrice) {
      if (value === 0) {
        setBundlePriceValidation({ type: 'minLength' })
      } else if (value > totalPrice) {
        setBundlePriceValidation({ type: 'maxLengthBundle' })
      } else {
        setBundlePriceValidation(null)
      }
    }
  }

  const sanitizeValue = (value) => {
    const parsedValue = parseFloat(String(value))
    if (isNaN(parsedValue)) return ''
    handleValidation(parsedValue)
    const formattedValue = parsedValue.toFixed(2)
    setBundlePrice(formattedValue)
    return clearTitleAndTagsForbiddenWords(stripHTMLFromString(formattedValue)).trim()
  }

  const setDefaultValue = () => {
    if (product.price) {
      setInputValue(product.price)
      setBundlePrice(product.price)
    }
  }

  useEffect(() => {
    setDefaultValue()
  }, [])

  return (
    <div>
      <Controller
        name={name}
        id={name}
        control={control}
        rules={{
          required: {
            value: true,
            short: 'Required',
            message: errorMessage('price_required')
          },
          setValueAs: sanitizeValue
        }}
        render={({ field }) => (
          <CreatableSelect
            maxMenuHeight={299}
            {...props}
            name={name}
            data-testid="price-input"
            value={
              field.value
                ? { label: `$${field.value}`, value: field.value }
                : null
            }
            onChange={selectedOption => {
              const selectedValue = selectedOption ? selectedOption.value : ''
              const sanitized = sanitizeValue(selectedValue)
              field.onChange(sanitized)
              setInputValue(sanitized)
            }}
            onInputChange={(input, actionMeta) => {
              if (actionMeta.action === 'input-change') {
                const sanitizedInput = input
                .replace(/-/g, '')
                .replace(/\./g, ',')
                setInputValue(sanitizedInput)
              }
            }}
            onBlur={() => {
              field.onBlur()
              if (inputValue) {
                const sanitized = sanitizeValue(inputValue)
                field.onChange(sanitized)
                setInputValue(sanitized)
              } else {
                setBundlePrice('')
                setInputValue('')
                field.onChange('')
              }
            }}
            options={options}
            inputValue={inputValue}
            filterOption={null}
            placeholder=''
            styles={ComboSelectStyle}
          />
        )}
      />
    </div>
  )
})

export default Combobox
